<template>
  <div>
    <Title>
      Госуслуги
    </Title>

    <gosuslugi-delete v-if="del" :row="row" @close="del = false"/>

    <vue-good-table
        ref="vueTable"
        :columns="columns"
        :isLoading.sync="isLoading"
        :pagination-options="paginationOptions"
        :rows="rows"
        :totalRows="totalRecords"
        mode="remote"
        styleClass="primary-table primary-table--analysis"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :select-options="selectOptions"
    >
      <template slot="column-filter" slot-scope="{column, updateFilters }">
        <DatePicker
            v-if="column.filterOptions.inputType === 'date'"
            v-model="data.columnFilters.order_date"
            :isFilter="true"
            :placeholder="column.filterOptions.placeholder"
            @input="updateFilters(column, data.columnFilters.order_date)"
        />
      </template>
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'client'" class="relative">
          {{ getClientFullName(props.row) }}
        </div>
        <div v-else-if="props.column.field === 'user'" class="relative">
          {{ getUserFullName(props.row) }}
        </div>
        <div v-else-if="props.column.field === 'gosuslugi_statuses'" class="relative">
          <div v-for="(item, index) in props.row.gosuslugi_statuses" :title="item.error" :key="index">
            {{ item.type }}: {{ item.status }}
          </div>

          <a-dropdown class="primary-table__actions-dropdown">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <a-icon type="more"/>
            </a>
            <a-menu slot="overlay" class="primary-table__dropdown-menu">
              <a-menu-item>
                <a @click="$router.push(`/analysis/${props.row.id}/edit`)">
                  <a-icon type="edit"/>
                  Редактировать
                </a>
              </a-menu-item>
              <a-menu-item>
                <a @click="onDelete(props.row)">
                  <a-icon type="delete"/>
                  Удалить
                </a>
              </a-menu-item>
            </a-menu>

          </a-dropdown>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <Pagination
          :total="props.total"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
        />
      </template>
      <div slot="emptystate" class="text-center">
        Нет данных
      </div>
      <template slot="loadingContent">
        <span class="vgt-loading__content">
          Загрузка...
        </span>
      </template>
      <div slot="selected-row-actions">
        <v-button @click.native="sendGosuslugi">Отправить</v-button>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import Vue from 'vue';
import {vueTable} from "@/mixins/vue-table";
import {getAll} from "@/api/gosuslugi/getAll";
import {send} from "@/api/gosuslugi/send";
import Title from "@/components/ui/Title";
import moment from 'moment';
import DatePicker from "@/components/ui/DatePicker";
import ADropdown from 'ant-design-vue/lib/dropdown';
import AMenu from 'ant-design-vue/lib/menu';
import AIcon from 'ant-design-vue/lib/icon';
import VButton from '@/components/ui/VButton';
import Pagination from '@/components/ui/Pagination';
import GosuslugiDelete from "./Delete";

Vue.use(AMenu);

export default {
  components: {
    Title,
    AIcon,
    ADropdown,
    DatePicker,
    VButton,
    Pagination,
    GosuslugiDelete,
  },
  mixins: [vueTable],
  data() {
    return {
      moment,
      getAll,
      load: false,
      del: false,
      data: {
        columnFilters: {
          order_date: null,
        },
        page: 1,
        perPage: 10,
        sort: {
          field: 'id',
          type: 'desc'
        }
      },
      selectOptions: {
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionText: 'выбрано',
        clearSelectionText: 'очистить',
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          field: 'id',
          label: 'ID',
          sortable: true,
          width: '7rem',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'ID',
            trigger: 'enter',
            customFilter: true,
            inputType: 'text',
          },
        },
        {
          field: 'client',
          label: 'ФИО',
          sortable: true,
          width: '20%',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'ФИО',
            trigger: 'enter',
            customFilter: true,
            inputType: 'text',
          },
        },
        {
          field: 'user',
          label: 'Ответственный',
          sortable: true,
          width: '20%',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'Ответственный',
            trigger: 'enter',
            customFilter: true,
            inputType: 'text',
          },
        },
        {
          field: 'order_date',
          label: 'Дата создания',
          sortable: true,
          width: '15%',
          tdClass: 'fold',
          type: 'date',
          dateInputFormat: 'dd.MM.yyyy HH:mm',
          dateOutputFormat: 'dd.MM.yyyy HH:mm',
          filterOptions: {
            enabled: true,
            placeholder: 'Дата',
            trigger: 'enter',
            customFilter: true,
            inputType: 'date',
          },
          formatFn: (value) => {
            return this.moment(value).format('DD.MM.YYYY HH:mm');
          }
        },
        {
          field: 'filter_type',
          label: 'Тип',
          sortable: false,
          width: '20%',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'Тип',
            trigger: 'enter',
            filterDropdownItems: [
              {value: 'pcr', text: 'ПЦР'},
              {value: 'pcr_ru_en', text: 'ПЦР (ru+en)'},
              {value: 'pcr_ru', text: 'ПЦР (ru)'},
              {value: 'pcr_en', text: 'ПЦР (en)'},
              {value: 'antibody', text: 'Антитела'},
              {value: 'antibody_m_g', text: 'Антитела (m+g)'},
              {value: 'antibody_m', text: 'Антитела (m)'},
              {value: 'antibody_g', text: 'Антитела (g)'},
              {value: 'antibody_gs', text: 'Антитела (g) (капсидный белок)'},
            ],
            customFilter: true,
            inputType: 'select',
          },
        },
        {
          field: 'gosuslugi_statuses',
          label: 'Статус',
          sortable: false,
          width: '25%',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'Статус',
            trigger: 'enter',
            filterDropdownItems: [
              {value: 'not_sent', text: 'Не отправлен'},
              {value: 'sent', text: 'Отправлен'},
              {value: 'sending_error', text: 'Ошибка отправки'},
              {value: 'awaiting_status', text: 'Ожидает статуса'},
              {value: 'error', text: 'Ошибка'},
            ],
            customFilter: true,
            inputType: 'select',
          },
        },
      ]
    }
  },
  methods: {
    sendGosuslugi() {
      let rows = this.$refs['vueTable'].selectedRows;

      rows.forEach(row => {
        send({id: row.id})
            .then(response => {
              if (typeof response.data === 'string') {
                this.getNotify('info', response.data);
              } else {
                if (response.data.success) {
                  const types = this.getTypes(response.data.success);

                  if (types.length) {
                    this.getNotify('success', `Анализ ${row.id} отправлен (${types.join(', ')})`);
                  }
                }

                if (response.data.error) {
                  const types = this.getTypes(response.data.error);

                  if (types.length) {
                    this.getNotify('error', `Ошибка отправки анализа ${row.id} (${types.join(', ')})`);
                  }
                }
              }
            })
            .catch(() => {
              this.getNotify('error', `Ошибка отправки анализа ${row.id}`);
            })
            .finally(() => {
              this.loadItems();
            })
      });
    },
    getTypes(arr) {
      let types = [];

      arr.forEach(item => {
        switch (true) {
          case item.slice(-2) === '-1':
            types.push('ПЦР');
            break;
          case item.slice(-2) === '-2':
            types.push('SARS-Cov2 (lgG)');
            break;
          case item.slice(-2) === '-3':
            types.push('SARS-Cov2 (lgM)');
            break;
        }
      })

      return types;
    },
    getUserFullName(row) {
      return (row.user.surname || '') + ' ' +
          (row.user.name || '') + ' ' +
          (row.user.patronymic || '');
    },
    getClientFullName(row) {
      let ru = '';
      let en = '';

      if (row.client.attributes.ru.surname) {
        ru = (row.client.attributes.ru.surname || '') + ' ' +
          (row.client.attributes.ru.name || '') + ' ' +
          (row.client.attributes.ru.patronymic || '');
      }

      if (row.client.attributes.en.surname) {
        en = (row.client.attributes.en.surname || '') + ' ' +
          (row.client.attributes.en.name || '') + ' ' +
          (row.client.attributes.en.patronymic || '');
      }

      return ru ? ru : en;
    },
  },
}
</script>
